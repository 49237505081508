import cx from 'classnames'
import { Fragment } from 'react'

import { type SanityAccountDetailsStrings } from '@data/sanity/queries/types/blocks'
import { useUser } from '@lib/auth'
import { getLinkPageUrl } from '@lib/routes'

import ButtonLink from '@components/buttons/button-link'

interface AccountDetailsProps {
  className?: string
  accountDetailsStrings: SanityAccountDetailsStrings
}

const AccountDetails = ({
  accountDetailsStrings,
  className,
}: AccountDetailsProps) => {
  const { user } = useUser()

  const addressCount = user?.addresses?.length ?? 0
  const defaultAddress =
    user?.addresses?.find((address) => address.isDefault) ??
    user?.addresses?.[0]

  return (
    <div className={cx('space-y-6', className)}>
      <h2>{accountDetailsStrings.accountAccountDetailsHeading}</h2>

      <div className="space-y-3">
        {defaultAddress && (
          <div className="text-sm leading-normal">
            {defaultAddress.formatted.map((addressLine, index) => (
              <Fragment key={index}>
                {addressLine}
                {defaultAddress.formatted[index + 1] && <br />}
              </Fragment>
            ))}
          </div>
        )}

        <ButtonLink
          className="uppercase"
          href={getLinkPageUrl('accountAddressPage')}
        >
          {accountDetailsStrings.accountViewAddresses} ({addressCount})
        </ButtonLink>
      </div>
    </div>
  )
}

export default AccountDetails
