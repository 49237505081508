import {
  type SanityResponsiveSpacing,
  type SanitySpacingValues,
  type SanityBreakpoint,
} from '@data/sanity/queries/types/modules'

type SpacingValues = SanitySpacingValues

type Direction = 'top' | 'right' | 'bottom' | 'left'

type ScreenSize = SanityBreakpoint

const getSpacingDirectionClass = (
  breakpoint: ScreenSize,
  typeClassPrefix: string,
  direction: Direction,
  spacingValues?: SpacingValues,
): string => {
  const directionClassSuffix = direction.charAt(0)
  const baseClass = `${typeClassPrefix}${directionClassSuffix}`
  const value = spacingValues?.[direction]

  return typeof value !== 'undefined'
    ? `${!!breakpoint.trim() ? `${breakpoint}:` : ''}${baseClass}-${value}`
    : ''
}

export const getSpacingClasses = (
  breakpoint: ScreenSize,
  spacingValues?: SpacingValues,
  direction?: Direction,
) => {
  const topClasses = getSpacingDirectionClass(
    breakpoint,
    'p',
    'top',
    spacingValues,
  )

  const rightClasses = getSpacingDirectionClass(
    breakpoint,
    'p',
    'right',
    spacingValues,
  )

  const bottomClasses = getSpacingDirectionClass(
    breakpoint,
    'p',
    'bottom',
    spacingValues,
  )

  const leftClasses = getSpacingDirectionClass(
    breakpoint,
    'p',
    'left',
    spacingValues,
  )

  if (direction === 'top') {
    return topClasses
  }

  if (direction === 'right') {
    return rightClasses
  }

  if (direction === 'bottom') {
    return bottomClasses
  }

  if (direction === 'left') {
    return leftClasses
  }

  return [topClasses, rightClasses, bottomClasses, leftClasses]
}

export const getResponsiveSpacingClasses = (
  responsiveSpacing: SanityResponsiveSpacing,
) =>
  responsiveSpacing.reduce((result: string[], breakpoint) => {
    const spacingClasses = getSpacingClasses(
      breakpoint.size,
      breakpoint.spacing,
    ) as string[]

    return [...result, ...spacingClasses]
  }, [])
