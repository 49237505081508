import { useContext, useState } from 'react'
import cx from 'classnames'

import {
  type SanityAccountAddressDetailsStrings,
  type SanityAddressFormStrings,
} from '@data/sanity/queries/types/blocks'
import { useDeleteAddress } from '@lib/shopify/graphql/customer-address'
import { useUser } from '@lib/auth'
import { countryNames, defaultCountryCodes } from '@lib/country'
import { LanguageContext } from '@lib/language-context'
import { StringsContext } from '@lib/strings-context'
import { type AddressFormValues } from '@lib/user'

import Button, { ButtonSize, ButtonVariant } from '@components/buttons/button'
import AddressForm, { AddressFormMode } from './address-form'

interface AccountAddressDetailsProps {
  className?: string
  accountAddressDetailsStrings: SanityAccountAddressDetailsStrings
  addressFormStrings: SanityAddressFormStrings
}

const AccountAddressDetails = ({
  accountAddressDetailsStrings,
  addressFormStrings,
  className,
}: AccountAddressDetailsProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const [formMode, setFormMode] = useState<AddressFormMode>(
    AddressFormMode.CREATE,
  )
  const [addressId, setAddressId] = useState('')
  const [showForm, setShowForm] = useState(false)

  const initialValues: AddressFormValues = {
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    country: countryNames.en[defaultCountryCodes[locale]],
    zip: '',
    phone: '',
    isDefault: false,
  }
  const [formDefaultValues, setFormDefaultValues] =
    useState<AddressFormValues>(initialValues)

  const { user } = useUser()
  const deleteAddress = useDeleteAddress()

  const token = user?.token
  const addresses = user?.addresses ?? []

  if (!token) {
    return null
  }

  const showCreateForm = () => {
    setFormMode(AddressFormMode.CREATE)
    setFormDefaultValues(initialValues)
    setAddressId('')
    setShowForm(true)
  }

  const showEditForm = (id: string, values: AddressFormValues) => {
    setFormMode(AddressFormMode.EDIT)
    setFormDefaultValues(values)
    setAddressId(id)
    setShowForm(true)
  }

  return (
    <div className={cx(className)}>
      {showForm && (
        <AddressForm
          addressFormStrings={addressFormStrings}
          mode={formMode}
          hide={() => setShowForm(false)}
          addressId={addressId}
          defaultValues={formDefaultValues}
        />
      )}

      {!showForm && (
        <div className="space-y-12">
          <div className="space-y-3.5">
            <h2>{accountAddressDetailsStrings.accountAddAddressHeading}</h2>

            <Button
              variant={ButtonVariant.OUTLINED}
              onClick={showCreateForm}
              className="max-w-sm w-full"
            >
              {accountAddressDetailsStrings.accountAddAddress}
            </Button>
          </div>

          <div className="space-y-6">
            {addresses
              .sort((a, b) =>
                a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1,
              )
              .map((address) => (
                <div key={address.id} className="border-t pt-6 space-y-3">
                  {address.isDefault && (
                    <h3 className="is-h2">
                      {accountAddressDetailsStrings.accountDefaultAddressLabel}
                    </h3>
                  )}

                  <div>
                    {address.formatted.map((addressLine) => (
                      <p key={addressLine}>{addressLine}</p>
                    ))}
                  </div>

                  <div className="flex gap-x-3 pt-0.5">
                    <Button
                      className="uppercase"
                      onClick={() => showEditForm(address.id, address.values)}
                    >
                      {strings.buttonEdit}
                    </Button>

                    <Button
                      className="uppercase text-gray-dark"
                      onClick={() => deleteAddress(address.id, token)}
                    >
                      {strings.buttonDelete}
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default AccountAddressDetails
