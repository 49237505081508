interface CookieDeclaration {
  cookieBotId: string
}

const CookieDeclaration = ({ cookieBotId }: CookieDeclaration) => {
  return (
    <script
      id="CookieDeclaration"
      src={`https://consent.cookiebot.com/${cookieBotId}/cd.js`}
      type="text/javascript"
      async
    />
  )
}

export default CookieDeclaration
