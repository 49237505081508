import { useContext } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'

import { SanityAccountHeaderStrings } from '@data/sanity/queries/types/blocks'
import { getPageUrl, pageRoutes } from '@lib/routes'
import { LanguageContext } from '@lib/language-context'
import { useLogoutUser } from '@lib/auth'

import SimpleLink from '@components/simple-link'
import Button from '@components/buttons/button'

interface AccountHeaderProps {
  accountHeaderStrings: SanityAccountHeaderStrings
  className?: string
}

const AccountHeader = ({
  accountHeaderStrings,
  className,
}: AccountHeaderProps) => {
  const router = useRouter()
  const { locale } = useContext(LanguageContext)
  const logoutUser = useLogoutUser()

  const isAccountPage =
    router.pathname.substring(1) === pageRoutes.accountPage.join('/')
  const isAccountAddressPage =
    router.pathname.substring(1) === pageRoutes.accountAddressPage.join('/')

  return (
    <div className={cx('space-y-3', className)}>
      <h1 className="is-large !leading-none">
        {isAccountPage && accountHeaderStrings.accountTitle}
        {isAccountAddressPage && accountHeaderStrings.accountAddressesTitle}
      </h1>

      {isAccountPage && (
        <Button onClick={() => logoutUser()} className="text-left uppercase">
          {accountHeaderStrings.accountLogoutLink}
        </Button>
      )}

      {isAccountAddressPage && (
        <SimpleLink
          href={getPageUrl(locale, 'accountPage')}
          className="inline-flex text-xs uppercase underline hover:no-underline"
        >
          {accountHeaderStrings.accountOverviewLink}
        </SimpleLink>
      )}
    </div>
  )
}

export default AccountHeader
