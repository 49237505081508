import cx from 'classnames'

import { type SanityBlock } from '@data/sanity/queries/types/blocks'

import Accordions from '@blocks/accordions'
import AccountAddressDetails from '@blocks/account/account-address-details'
import AccountDetails from '@blocks/account/account-details'
import AccountHeader from '@blocks/account/account-header'
import AccountOrderList from '@blocks/account/account-order-list'
import BlogPostCard from '@blocks/blog/blog-post-card'
import ProductCarousel from '@blocks/shop/product-carousel'
import ContentCarousel from '@blocks/content-carousel'
import CookieDeclaration from '@blocks/cookiebot/cookie-declaration'
import Freeform from '@blocks/freeform'
import LinkBlock from '@blocks/link-block'
import LoginForm from '@blocks/account/login-form'
import Newsletter from '@blocks/newsletter'
import PasswordRecoveryForm from '@blocks/account/password-recovery-form'
import ProductCard from '@blocks/shop/product-card'
import SignupForm from '@blocks/account/signup-form'
import TeamMemberCard from '@blocks/team-member-card'
import Video from '../components/video'
import Photo from '@components/photo'

interface GridBlockProps {
  block: SanityBlock
}

const GridBlock = ({ block }: GridBlockProps) => {
  switch (block._type) {
    case 'accordions': {
      const { items } = block

      return <Accordions items={items} />
    }

    case 'accountAddressDetails': {
      const { active, accountAddressDetailsStrings, addressFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <AccountAddressDetails
          accountAddressDetailsStrings={accountAddressDetailsStrings}
          addressFormStrings={addressFormStrings}
        />
      )
    }

    case 'accountDetails': {
      const { active, accountDetailsStrings } = block

      if (!active) {
        return null
      }

      return <AccountDetails accountDetailsStrings={accountDetailsStrings} />
    }

    case 'accountHeader': {
      const { active, accountHeaderStrings } = block

      if (!active) {
        return null
      }

      return <AccountHeader accountHeaderStrings={accountHeaderStrings} />
    }

    case 'accountOrderList': {
      const { active, accountOrderListStrings } = block

      if (!active) {
        return null
      }

      return (
        <AccountOrderList
          accountOrderListStrings={accountOrderListStrings}
          className="mb-16"
        />
      )
    }

    case 'blogPostCard': {
      const { post } = block

      return <BlogPostCard post={post} />
    }

    case 'productCarousel': {
      const { title, products } = block

      return <ProductCarousel title={title} products={products} />
    }

    case 'contentCarousel': {
      const { items } = block

      return <ContentCarousel items={items} />
    }

    case 'cookieDeclaration': {
      const { active, cookieBotId } = block

      if (!active || !cookieBotId) {
        return null
      }

      return <CookieDeclaration cookieBotId={cookieBotId} />
    }

    case 'figure': {
      return <Photo image={block} imageClassName="w-full" />
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = block

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'iframe': {
      const { title, url, width, height } = block

      return (
        <iframe
          title={title}
          src={url}
          className={cx({ 'w-full': !width })}
          width={width}
          height={height}
        />
      )
    }

    case 'linkBlock': {
      const { link, text, textColor, backgroundType, photo, muxVideo } = block

      return (
        <LinkBlock
          link={link}
          text={text}
          textColor={textColor}
          backgroundType={backgroundType}
          photo={photo}
          muxVideo={muxVideo}
        />
      )
    }

    case 'loginForm': {
      const { active, loginFormStrings } = block

      if (!active) {
        return null
      }

      return <LoginForm loginFormStrings={loginFormStrings} />
    }

    case 'newsletter': {
      const { _key, service, listId, submit, successMsg, errorMsg, terms } =
        block

      return (
        <Newsletter
          id={_key}
          service={service}
          listId={listId}
          submit={submit}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
        />
      )
    }

    case 'passwordRecoveryForm': {
      const { active, passwordRecoveryFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <PasswordRecoveryForm
          passwordRecoveryFormStrings={passwordRecoveryFormStrings}
        />
      )
    }

    case 'predefinedContentBlock': {
      const { contentBlocks } = block

      return (
        <>
          {contentBlocks?.map((contentBlock) => (
            <GridBlock key={contentBlock._key} block={contentBlock} />
          ))}
        </>
      )
    }

    case 'productCard': {
      const { product } = block

      return (
        <ProductCard product={product} showThumbnails showOption showPrice />
      )
    }

    case 'signupForm': {
      const { active, signupFormStrings } = block

      if (!active) {
        return null
      }

      return <SignupForm signupFormStrings={signupFormStrings} />
    }

    case 'teamMemberCard': {
      const { teamMember } = block

      return <TeamMemberCard teamMember={teamMember} />
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio } = block

      return (
        <Video
          type={type}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
        />
      )
    }
  }
}

export default GridBlock
