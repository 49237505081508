import cx from 'classnames'
import { type ChangeEvent, type FocusEvent, useState } from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'

import Icon from './icon'

export interface DropdownOption {
  value: string
  title: string
}

interface InputDropdownProps {
  id: string
  formRegister: UseFormRegisterReturn
  options: DropdownOption[]
  errorMessage?: string
  label?: string
  defaultValue?: string
  placeholder?: string
  isMinimal?: boolean
  className?: string
}

const InputDropdown = ({
  id,
  formRegister,
  options,
  errorMessage,
  label,
  defaultValue,
  placeholder,
  isMinimal,
  className,
}: InputDropdownProps) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <div className={cx('grid', className)}>
      <div className="flex flex-col gap-y-1 relative text-left">
        {label && (
          <label htmlFor={id} className="text-xs uppercase">
            {label}
          </label>
        )}

        <div
          className={cx(
            'relative appearance-none w-full h-full',
            'bg-input-bg text-input-text',
            {
              'border text-sm py-4 px-3': !isMinimal,
              'border-b text-xs py-3': isMinimal,
              'border-input-border': !errorMessage && !isMinimal,
              'border-input-minimal-border': !errorMessage && isMinimal,
              'border-error': errorMessage,
            },
          )}
        >
          <select
            id={id}
            ref={formRegister.ref}
            name={formRegister.name}
            className="absolute block top-0 left-0 w-full h-full opacity-0 z-10 cursor-pointer focus:outline-none"
            value={value}
            onBlur={(event: FocusEvent<HTMLSelectElement>) => {
              formRegister.onBlur(event)
              setValue(event.target.value)
            }}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              formRegister.onChange(event)
              setValue(event.target.value)
            }}
            aria-label={label}
          >
            <option value="">{placeholder}</option>
            {options.map(({ value, title }) => (
              <option key={value} value={value}>
                {title}
              </option>
            ))}
          </select>

          <span
            className={cx(
              'flex justify-between items-center pointer-events-none relative pr-6',
              {
                'text-sm': !isMinimal,
                'text-xs': isMinimal,
              },
            )}
          >
            {options.find((option) => option.value === value)?.title ??
              placeholder}
            <span className="inline-flex items-center ml-2 absolute inset-y-0 right-0">
              <Icon
                id="input-dropdown"
                name="ChevronDown"
                className="block h-[1rem]"
                color="text-current"
              />
            </span>
          </span>
        </div>

        {errorMessage && (
          <span role="alert" className="text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default InputDropdown
