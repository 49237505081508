import { useContext } from 'react'
import cx from 'classnames'

import { type SanityBlogPostWithoutBody } from '@data/sanity/queries/types/blog'
import { getFormattedDate } from '@lib/format'
import { LanguageContext } from '@lib/language-context'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'
import Video from '@components/video'
import { SanityVideoAspectRatio } from '@data/sanity/queries/types/video'

interface BlogPostCardProps {
  post: SanityBlogPostWithoutBody
  imageAspectRatio?: number
  videoAspectRatio?: SanityVideoAspectRatio
  className?: string
}

const BlogPostCard = ({
  post,
  imageAspectRatio,
  videoAspectRatio,
  className,
}: BlogPostCardProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  if (!post) {
    return null
  }

  const { cover, slug, title, date, _createdAt: createdAt } = post

  return (
    <SimpleLink
      href={getLinkPageUrl('blogPost', slug.current)}
      className={cx('relative flex flex-col group', className)}
    >
      {cover?.type === 'image' && !!cover.image && (
        <Photo
          image={{
            ...cover.image,
            ...(imageAspectRatio && { customRatio: imageAspectRatio }),
          }}
          className="w-full"
          imageClassName="w-full"
        />
      )}

      {cover?.type === 'video' && !!cover.video && (
        <Video
          type={cover.video.type}
          muxVideo={cover.video.muxVideo}
          aspectRatio={videoAspectRatio ?? cover.video.aspectRatio}
          settings={{
            autoplay: true,
            controls: false,
            loop: true,
            muted: true,
            ...cover.video.settings,
          }}
          className="w-full"
        />
      )}

      <div className="sticky z-10 bottom-0 left-0 p-3 bg-pageBG">
        <h3 className="flex flex-col gap-y-1">
          <span>{getFormattedDate(date ?? createdAt, locale)}</span>
          <span>
            {title}{' '}
            <span className="hidden group-hover:inline">
              — {strings.blogPostReadMore}
            </span>
          </span>
        </h3>
      </div>
    </SimpleLink>
  )
}

export default BlogPostCard
